import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';

const BulletListIndustry = ({ data, className, spacer, wide, services }) => {
  const { list } = data || [];

  const bulletList = (list || '').split('\r\n').map((item, index) => (
    <li className="bullet-list-industry__item" key={index}>
      <Text className="body-sm body-sm_bold bullet-list-industry__item__text">
        {item}
      </Text>
    </li>
  ));

  const columnOffset = services ? 'offset-lg-1' : 'offset-lg-2';

  return (
    <Section
      className={cn(
        'bullet-list-industry',
        { 'bullet-list-industry_services': services },
        spacer && 'bullet-list-industry_spacer',
        [className]
      )}
    >
      <Container>
        <Row>
          <Column
            className={cn(
              'bullet-list-industry__content',
              `col-lgplus-${wide ? '7' : '6'}`,
              columnOffset
            )}
            lg="9"
          >
            <ul className="bullet-list-industry__block">{bulletList}</ul>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

BulletListIndustry.defaultProps = {
  className: '',
  wide: false,
};

BulletListIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  spacer: PropTypes.bool,
  className: PropTypes.string,
  wide: PropTypes.bool,
};

export default BulletListIndustry;
